<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Study deals
			</div>
		</div>
		<div>
			<edit-lesson-deals :lesson="lesson" />
		</div>
	</div>
</template>

<script>

	import EditLessonDeals  from '@/components/lessons/EditLessonDeals';
	import routeParams from '@/mixins/routeParams';
	import api from '@/services/api';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Edit lesson study deals'
		},
		components: {
			EditLessonDeals
		},
		mixins: [routeParams],
		data: () => ({
			lesson: {}
		}),
		computed: {
			getLessonTitle () {
				return this.lesson?.title;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					},
					{
						path: this.getCoursesRoute,
						text: 'Courses'
					},
					{
						path: this.getCourseRoute,
						text: this.lesson?.courseTitle
					},
					{
						path: this.getLessonRoute,
						text: this.getLessonTitle
					},
					{
						path: `${this.getLessonRoute}/deals`,
						text: 'Study deals'
					}
				];
			}
		},
		created () {
			this.setLesson();
		},
		methods: {
			async setLesson () {
				const lesson = await api.teaching.getLessonById({
					lessonId: this.getLessonId
				});
				if (!lesson) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.lesson = lesson;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>

<style lang="scss" scoped>
.page__info {
  margin-bottom: 24px;
}
</style>
